// 申遗承诺
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 数据列
            columnsData: [
                {
                    label: "事件",
                    prop: "SHIJIAN",
                    minWidth: ""
                },
                {
                    label: "发生时间",
                    prop: "SJ",
                    minWidth: ""
                },
                {
                    label: "发生地点",
                    prop: "DD",
                    minWidth: ""
                },
                {
                    label: "对遗产造成的负面影响",
                    prop: "DYCZCDFMYX",
                    minWidth: ""
                }
            ],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    btnType: 'icon',
                    operateType: 'columnSee',
                    title: '查看',
                    icon: 'el-icon-view',
                    backColor: 'transparent',
                    color: '#20a0ff'
                }]
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
};
export default selfData;